//赛事中心-选择课程
<template>
  <div class="competition_choose_lesson">
    <div class="content" v-if="!showLoading">
      <!-- 返回和章节名称 -->
      <div class="top_msg">
        <div @click.stop="goBack" class="back iconfont cloud-fanhui"></div>
        <div class="name">{{ chapterItem.eventChapterName }}</div>
      </div>

      <!-- 非Python章节显示的课程列表 -->
      <!-- <div class="show_lesson" v-if="lessonData.length > 0">
        <div :class="['item_lesson']" v-for="(item, index) in lessonData" :key="index">
          <img class="img_lesson" :src="item.coursewareCover" />

          <div class="title_content">
            <div class="item_title">{{ item.coursewareName }}</div>
          </div>

          <div class="bt_study" @click.stop="startStudyLesson(item)">
            开始学习
          </div>
        </div>
      </div> -->

      <!-- Python章节显示的表格课程 chapterType != 1 && -->
      <div class="show_table" v-if="lessonData.length > 0">
        <div class="table_title item_height_head">
          <div class="table_one com_flex border_right">序号</div>
          <div class="table_two com_flex border_right">课程主题</div>
          <div class="table_four com_flex">操作</div>
        </div>

        <div :class="['item_table_lesson', 'item_height', (index + 1) % 2 == 0 ? 'signle_bg' : 'double_bg']" v-for="(item, index) in lessonData" :key="index">
          <div class="table_one com_flex border_right">{{ index + 1 }}</div>

          <div class="table_two com_flex border_right">{{ item.eventTypeName }}</div>

          <div class="table_four com_flex">
            <div class="bt_study" @click.stop="startStudyLesson(item)">
              {{ item.type == 7 ? "开始下载" : "开始学习" }}
            </div>
          </div>
        </div>
      </div>

      <!-- 空视图 -->
      <el-empty description="暂无数据~" v-if="showEmptyView && lessonData.length == 0"></el-empty>
    </div>
    <!-- 加载框 -->
    <Loading v-else></Loading>
  </div>
</template>
<script>
export default {
  data() {
    return {
      //是否显示loading
      showLoading: true,

      //传递的章节对像
      chapterItem: null,

      //是否是Python类型的课程,需要选择课程界面传递过来
      chapterType: "",

      //课程章节Id
      eventCenterId: "",

      //课程ID
      eventChapterId: "",

      //是否是Python
      isPythonType: false,

      //是否显示空视图的标识
      showEmptyView: false,

      //课程列表的数据
      lessonData: []
    };
  },

  created() {
    this.getParams();
  },

  mounted() {
    this.httpData();
  },

  methods: {
    //获取传递参数
    getParams() {
      this.chapterItem = JSON.parse(sessionStorage.getItem("chapterItem"));
    },

    //开始学习
    startStudyLesson(item) {
      if (item.type != 7) {
        let passItem = item;
        passItem.eventChapterId = this.chapterItem.eventChapterId;
        passItem.eventCenterId = this.chapterItem.eventCenterId;
        sessionStorage.setItem(`chosseLessonItem`, JSON.stringify(passItem));
        this.$router.push({ name: "CompetitionCenterStudy" });
      } else {
        //判断用户是否登录,如果没登录提示登录后方可进行下载
        if (!this.$store.state.user.loginState) {
          this.showWarnMsg("登录后方可进行下载操作");
          return;
        }
        //获取文件名称
        let downLoadObj = item.info[0];
        let index = downLoadObj.url.lastIndexOf("/");
        let fileName = downLoadObj.url.slice(index + 1);
        console.log(`下载的url是${downLoadObj.url}-----下载的名称是:${fileName}`);
        //下载文件的操作
        this.dowloadFile(downLoadObj.url, fileName);
      }
    },

    //返回
    goBack() {
      this.$router.back(-1);
    },

    //获取列表数据
    async httpData() {
      let param = this.getHttpParams();
      param.type = 3;
      param.eventCenterId = this.chapterItem.eventCenterId;
      param.eventChapterId = this.chapterItem.eventChapterId;
      let res = await this.$http.fetchPost(this.$api.COMPETITION_API, param);

      if (res.data.state == 200) {
        //关闭Loading
        this.showLoading = false;

        //表示请求成功
        this.lessonData = res.data.data;

        if (!this.isEmpty(this.lessonData) && this.lessonData.length > 0) {
          //循环给每个条目加入一个响应式的控制遮罩的值
          this.lessonData.forEach(item => {
            this.$set(item, "isShow", false);
          });
        } else {
          //显示空视图
          this.showEmptyView = true;
        }
      } else {
        //直接将服务器返回的错误内容弹出提示
        this.showErrorMsg(this.getMesage(res.data.state));
      }
    },

    //下载文件
    dowloadFile(url, fileName) {
      // 创建隐藏的可下载链接
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      // 保存下来的文件名
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      // 移除元素
      document.body.removeChild(a);
    }
  },
  computed: {},
  components: {}
};
</script>
<style lang="scss" scoped>
.competition_choose_lesson {
  width: $common_width;
  margin: 0 auto;
  .content {
    display: flex;
    padding-top: 20px;
    display: flex;
    flex-direction: column;

    //顶部返回与章节名称
    .top_msg {
      height: 55px;
      display: flex;
      align-items: center;
      .back {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        font-size: 22px;
        font-weight: bold;
        height: 36px;
        width: 36px;
        background: #70cf87;
        border-radius: 50%;
      }

      .name {
        // margin-left: 14px;
        // min-width: 184px;
        // padding: 0 10px;
        // height: 42px;
        // display: flex;
        // align-items: center;
        // justify-content: center;
        // color: white;
        // background: $common_bg;
        // border-radius: 22px;
        margin-left: 14px;
        padding: 0 10px;
        min-width: 184px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        font-size: 16px;
        font-weight: bold;
        background: $common_bg;
        border-radius: 22px;
      }
    }

    //展示列表课程的内容
    .show_lesson {
      margin-top: 12px;
      margin-right: 20px;
      display: flex;
      flex-wrap: wrap;
      .item_lesson {
        margin-bottom: 28px;
        position: relative;
        width: 100%;
        height: 212px;
        background: white;
        border-radius: 8px;
        align-items: center;
        display: flex;
        cursor: pointer;
        .img_lesson {
          width: 140px;
          height: 112px;
          margin-left: 42px;
        }

        //标题和内容介绍
        .title_content {
          margin-left: 24px;
          color: black;
          .item_title {
            font-size: 18px;
            font-weight: bold;
          }
          .item_content {
            margin-top: 30px;
            font-size: 16px;
          }
        }

        //开始学习按钮
        .bt_study {
          position: absolute;
          cursor: pointer;
          right: 0;
          width: 154px;
          height: 48px;
          background: #fed420;
          border-radius: 42px;
          margin-right: 42px;
          color: black;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 16px;
          font-weight: bold;
        }
        .mengban {
          width: 100%;
          height: 100%;
          border-radius: 8px;
          position: absolute;
          background: rgba($color: #000000, $alpha: 0.2);
        }
      }

      .item_lesson:hover {
        background: rgba($color: #000000, $alpha: 0.2);
      }

      .hasMargin {
        margin-right: 60px;
      }

      .noMargin {
        margin-right: 0px;
      }
    }

    //展示表格的课程内容
    .show_table {
      padding-bottom: 40px;
      margin-top: 12px;

      //表头部分
      .table_title {
        display: flex;
        background: $common_bg;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        color: white;
        font-size: 16px;
        font-weight: bold;
      }

      //表格内容部分
      .item_table_lesson {
        flex: 1;
        overflow: hidden;
        display: flex;
        font-size: 16px;
        border-top: 2px solid white;

        .item_lesson_name {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .name_details {
            margin: 4px 0;
          }
        }
      }

      .table_one {
        flex: 2;
      }
      .table_two {
        flex: 3;
      }
      .table_three {
        flex: 4;
      }
      .table_four {
        flex: 3;
        display: flex;
        //开始学习按钮
        .bt_study {
          cursor: pointer;
          right: 0;
          width: 140px;
          height: 54px;
          background: #f7a901;
          border-radius: 42px;
          color: white;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 16px;
          font-weight: bold;
        }

        .bt_study:hover {
          // background: rgba($color: #fed420, $alpha: 0.8);
          opacity: 0.5;
          // background-color: red;
        }
      }

      .com_flex {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .border_right {
        border-right: 2px solid white;
      }

      .item_height_head {
        min-height: 68px;
      }

      .item_height {
        min-height: 99px;
      }

      .signle_bg {
        background: #a1daaf;
      }

      .double_bg {
        background: #cfe8d3;
      }
    }

    //显示空视图
    .empty_view {
      margin: 0 auto;
      margin-top: 10%;
      color: $text_gray;
    }
  }
}
</style>
